a {
  text-decoration: none !important;
  color: inherit !important;
}

/* Scroll bar */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.img-width-model {
  width: 100%;
  height: 100%;
  border-radius: 10%;
}

.border-radius-50 {
  border-radius: 50%;
}

.webkit-line-one {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;
}

.webkit-line-two {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grecaptcha-badge {
  visibility: hidden;
}
