@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply cursor-none md:cursor-auto;
  margin: 0;
  font-family: "Roboto-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  caret-color: transparent;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

input:focus,
textarea:focus {
  caret-color: auto;
  user-select: text;
}
.font-semibold {
  font-family: "Roboto-SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.font-bold {
  font-family: "Roboto-Black", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.forgot-password-span > p {
  color: #000000 !important;
  float: left;
}
.forgot-password-span > p:hover {
  cursor: pointer;
}

.col-form-label {
  padding-right: 0px !important;
}

@media only screen and (max-width: 600px) {
  .shop-list {
    justify-content: space-between;
  }
  .sticky button,
  .sticky a,
  .sticky a > svg {
    font-size: 14px;
  }
}
