.cart-title {
    padding-top: 15px;
    font-size: 18px;
    font-weight: bold;
}

.cartItem {
    overflow: auto;
    /* height: 70.8vh; */
    /* margin-top: 50px; */
    /* margin-bottom: 80px; */
    width: 96%;
}

.cartItem-Img {
    height: 90px;
    width: 90px;
}

.cartItem-Img-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.cartItem-details {
    font-size: 14px;
    font-weight: 500;
}

.cartItem-right-btn {
    background-color: rgb(111, 78, 55);
    width: 63px;
    height: 23px;
    margin: 2px;
    color: rgb(255, 255, 255);
    border-radius: 4px;
}

.cartItem-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    cursor: pointer;
}

.cartItem-btn i {
    font-size: 12px !important;
}

.cartItem-delete-btn {
    margin: 0 10px;
    cursor: pointer;
}

.cartItem-delete-btn i {
    color: rgb(255, 0, 0) !important;
    font-size: 18px !important;
}

.cartItem-tax-text {
    padding: 5px;
    text-align: center;
    font-size: 14px;
    font-family: SecondaryFont;
}

.cartItem-reviewItem {
    background-color: rgb(111, 78, 55);
    color: rgb(255, 255, 255);
    height: 50px;
    cursor: pointer;
}

.cartItem-reviewItem-left {
    margin-left: 17px;
}

.cartItem-reviewItem-left-qty {
    font-size: 12px;
    margin-bottom: 3px;
}

.cartItem-reviewItem-left-price {
    font-size: 16px;
    font-weight: 500;
}

.cartItem-reviewItem-right {
    margin-right: 17px;
    font-size: 18px;
    font-weight: bold;
}

.cartItem-reviewItem-right i {
    margin-left: 5px;
}

.modal-content {
    background: #fff !important;
    padding: 20px;
    /* border-radius: 8px; */
    width: 90%;
    max-width: 400px;
    text-align: center;
}

.modal-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.btn-confirm,
.btn-mclose {
    padding: 10px 20px !important;
    margin: 5px auto !important;
    cursor: pointer;
    border-radius: 4px !important;
    width: 100%;
}

.btn-confirm {
    background-color: var(--primary-color);
    color: #1b1d29;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.btn-menu-modal-close {
    background-color: var(--primary-color);
    color: #1b1d29;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    width: 100%
}

.btn-confirm:hover {
    background-color: var(--primary-color);
}

.btn-mclose {
    background-color: #ccc !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal-content {
    max-height: 80vh;
    overflow-y: auto;
    background: white;
    padding: 20px;
    border-radius: 0px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: var(--bs-body-bg) !important;
    padding: 20px;
    width: 85%;
    max-width: 850px;
    text-align: center;
}

.menu-items-list {
    margin-top: 20px;
    max-height: 60vh;
    overflow-y: auto;
    /* padding-right: 10px; */
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.menu-items-list::-webkit-scrollbar {
    display: none; 
}

.menu-item {
    margin-bottom: 15px;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
}

.menu-item-name {
    font-weight: bold;
}

.menu-item-description {
    color: #555;
}

.menu-item-price {
    color: #28a745;
}

.menuBanner-main {
    margin-bottom: 100px;
}

.item-container {
    display: flex;
    padding: 15px;
}

.item-image {
    width: 90px;
    height: 80px;
    margin-right: 8px;
}

.menu-item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.item-details {
    flex: 1;
}

.item-name-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.item-name {
    font-weight: bold;
    font-size: 1rem;
}

.item-name-order {
    font-weight: bold;
    font-size: 0.8rem;
}

.item-title-value {
    font-weight: normal;
}

.item-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tag {
    background: #f1f1f1;
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 0.8rem;
}

.item-price {
    font-size: 1.1rem;
    color: #28a745;
    margin-top: 10px;
}

.item-description {
    margin-top: 10px;
    color: #555;
}

/* Unified button container for better alignment and spacing */
.itemDetails-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

/* Style for "Add to Cart" button */
.itemDetails-add-btn {
    background-color: var(--primary-color);
    color: #1b1d29;
    padding: 4px 12px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    transition: background-color 0.3s ease;
    width: 100%;
    /* max-width: 120px; */
}

.itemDetails-add-btn:hover {
    background-color: var(--primary-color);
}

.itemDetails-qty-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    /* width: 100%; */
    max-width: 150px;
    margin-top: 10px;
}

.itemDetails-qty-button {
    background-color: var(--primary-color);
    color: #1b1d29;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.itemDetails-qty-button:hover {
    background-color: var(--primary-color);
}

.itemDetails-qty-display {
    font-size: 16px;
    font-weight: bold;
    min-width: 20px;
    text-align: center;
    color: #1b1d29;
}

.search-sort-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    /* padding: 0 10px; */
}

.menu-modal-button-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    /* padding: 0 10px; */
}

.search-input {
    width: 60%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.sort-dropdown {
    width: 35%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.cart-item-img {
    height: 100%;
    border-radius: 10%;
}

.suggestions {
    background: #fff;
    border: 1px solid #ddd;
    max-height: 150px;
    overflow-y: auto;
    margin-top: 5px;
    border-radius: 4px;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f1f1f1;
}

.autocomplete-wrapper {
    position: relative;
    width: 100%;
}

.suggestions-container {
    position: absolute;
    top: 100%;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
}

/* Individual suggestion styling */
.suggestion-address-item {
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.suggestion-address-item:hover {
    background-color: #f1f1f1;
}

.modal-address-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.status-badge {
    padding: 5px 10px;
    border-radius: 9999px;
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    font-size: 0.85rem;
}

.status-badge.initial {
    background-color: #ccc;
    color: #333;
}

.status-badge.pending {
    background-color: #ff9800;
    color: white;
}

.status-badge.in-progress {
    background-color: #2196f3;
    color: white;
}

.status-badge.completed {
    background-color: #4caf50;
    color: white;
}

.status-badge.cancelled {
    background-color: #f44336;
    color: white;
}

/* Card hover effect */
.bg-white.rounded-lg.shadow-md {
    transition: transform 0.3s ease;
}

.bg-white.rounded-lg.shadow-md:hover {
    transform: scale(1.02);
}

/* Modal overlay */
.bg-black.bg-opacity-50 {
    backdrop-filter: blur(2px);
}

/* Set a max height and enable scroll */
.scroll-container {
    max-height: 60vh;
    /* Adjust as necessary */
    overflow-y: auto;
    padding-right: 10px;
    /* Space for scrollbar */
}

/* Optional styling for scrollbar appearance */
.scroll-container::-webkit-scrollbar {
    width: 6px;
}

.scroll-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 3px;
}

.scroll-container::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 3px;
}

.padding-bottom-3rem {
    padding-bottom: 3rem !important;
}

.payment-section {
    position: fixed;
    bottom: 50px;
    left: 0;
    padding: 10px;
}

@media only screen and (max-width: 1200px) {

    /* .cartItem{
        margin-top: 25px;
    } */
    .cartItem-details {
        font-size: 10px;
    }

    .cartItem-right-btn {
        width: 50px;
        height: 20px;
    }

    .cartItem-btn i {
        font-size: 10px;
    }

    .cartItem-qty {
        font-size: 12px;
    }

    .cartItem-reviewItem-left-qty {
        font-size: 12px;
    }

    .cartItem-reviewItem-left-price {
        font-size: 14px;
    }

    .cartItem-reviewItem-right {
        font-size: 16px;
    }

    .cartItem-tax-text {
        font-size: 10px;
    }

    .modal-content {
        padding: 10px;
    }
}